(function(factory) {
    'use strict';

    if( document.querySelector('.homepage-swiper') ) {
        factory(window.Clique);
    }
})(function(_c) {
    'use strict';

    // globals
    var Swiper = window.Swiper;

    // elements
    var container   = document.querySelector('.homepage-swiper');
    var nextButton  = document.querySelector('.swiper-button-next');
    var prevButton  = document.querySelector('.swiper-button-prev');

    // functions
    function onReady() {
        new Swiper(container, {
            speed          : 500,
            nextButton     : nextButton,
            prevButton     : prevButton,
            centeredSlides : true,
        });
    }

    // init
    _c.$doc.on('ready.homeSwiper', onReady);
});