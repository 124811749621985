
(function(factory) {
	'use strict';

	if( document.querySelector('.architects-svg') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// utility methods
	// http://stackoverflow.com/questions/30355241/get-the-length-of-a-svg-line-rect-polygon-and-circle-tags
	var tools = {
		getCircleLength : function(el){
			var r = el.attr('r');
			var circleLength = 2 * Math.PI * r;
			return circleLength;
		},
		getPolygonLength : function(el) {
			var xStart;
			var yStart;
			var x1 = null;
			var x2;
			var y1 = null;
			var y2;
			var lineLength = 0;
			var points = el.points;
			var i = 0;
			while(i < points.length) {
				var point = points[i];

				// assign points
				if(x1 === null && y1 === null) {
					xStart = x1 = point.x;
					yStart = y1 = point.y;
					i++;
					continue;
				}

				// on to "next" set
				x2 = point.x;
				y2 = point.y;

				// calc distance between points
				lineLength += Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

				// assign new "previous" values
				x1 = x2;
				y1 = y2;

				// if we're at the end...
				if(i === points.length - 1) {
					lineLength += Math.sqrt(Math.pow(xStart - x1, 2) + Math.pow(yStart - y1, 2));
				}

				i++;
			}
			return lineLength;
		},
		getPathLength : function(el) {
			var pathCoords = !! el.get ? el.get(0) : el;
			return pathCoords.getTotalLength();
		}
	};

	// global vars
	var ScrollMagic = window.ScrollMagic;
	var controller  = _c.controller;
	var $paths      = _c.$('.architects-svg path');
	var timeout;

	function bindSVGScrollListener() {
		if( timeout ) {
			clearTimeout(timeout);
			timeout = null;
		}

		if( ! $paths.length ) {
			$paths = _c.$('.architects-svg path');
		}

		var target = document.querySelector('.architects-svg');
		if( ! target ) {
			timeout = setTimeout(bindSVGScrollListener, 100);
			return;
		}
		new ScrollMagic.Scene({
			triggerElement : target,
			triggerHook    : 'onEnter',
			duration       : target.clientHeight,
			offset         : 100,
		})
		.on('enter', function(e) {
			this.off('enter');
			_c.$(target).addClass('visible');
			_c.$('.compass').addClass('visible');
			setTimeout(function() {
				e.target.remove();
			}, 2000);
		})
		.addTo(controller);
	}

	function setupSVG() {
		$paths.each(function() {
			var $path = _c.$(this);
			var delay = Math.random() + 1;
			var _length;
			if( $path.is('path') ) {
				_length = tools.getPathLength( this );
			} else {
				_length = tools.getPolygonLength( this );
			}
			$path.css({
				'stroke-dasharray'  : _length,
				'stroke-dashoffset' : _length,
				'transition-delay'  : delay + 's'
			}).data({
				length : _length
			});
		});
	}

	function setupCompass() {
		_c.$('.compass').find('circle').each(function() {
			var $element = _c.$(this);
			var _length = tools.getCircleLength( $element );
			var delay = Math.random() + 1;
			$element.css({
				'stroke-dasharray'  : _length,
				'stroke-dashoffset' : _length,
				'transition-delay'  : delay + 's'
			}).data({
				length : _length
			});
		});
		_c.$('.compass').find('path').each(function() {
			var $element = _c.$(this);
			var _length = tools.getPathLength( $element );
			var delay = Math.random() + 1;
			$element.css({
				'stroke-dasharray'  : _length,
				'stroke-dashoffset' : _length,
				'transition-delay'  : delay + 's'
			}).data({
				length : _length
			});
		});
	}


	function init() {
		setupSVG();
		bindSVGScrollListener();
		setupCompass();
	}

	_c.$win.one('load.architects', init);
});
