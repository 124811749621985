
(function(factory) {
	'use strict';

	if( document.querySelector('.banner') ) {
		factory(window.Clique);
	} else {
		var fn = window.onload;
		window.onload = function() {
			if( fn ) {
				fn();
			}
			window.Clique.$doc.trigger('canscrollend');
			// document.dispatchEvent(new CustomEvent('canscrollend'));
		};
	}
})(function(_c) {
	'use strict';

	// global vars
	var $section = _c.$('.section-banner');
	var $image   = _c.$('.banner-logo img');
	var endCount = 0;
	var evt      = _c.support.transition.end + '.banner';
	var $logo    = _c.$('.banner-logo');

	function triggerScreenshot() {
		console.log('page_ready');
	}

	function hidingListener() {
		$logo.off('.banner');
		$logo.on(evt, function() {

			// guards
			endCount++;
			if( endCount < 2 ) {
				return;
			}

			// unbind listener
			$logo.off('.banner');

			_c.$('.main-home').addClass('can-scroll');
			_c.$doc.trigger('canscroll');

			var timeout = setTimeout(function() {
				clearTimeout(timeout);
				timeout = null;

				setTimeout(triggerScreenshot, 500);
			}, 0);
		});
	}

	function hideBanner() {
		endCount = 0;

		// bind listener
		hidingListener();

		// hide content
		$section
			.removeClass('active')
			.addClass('hiding');
	}

	function bindScrollListener() {
		$section.on('mousewheel.banner', function(e) {
			// guards
			if( ! e.deltaY || e.deltaY > -1 ) {
				return;
			}

			// unbind listener
			$section.off('.banner');

			// hide banner
			hideBanner();
		});
	}

	$image.on(evt, function() {

		// guards
		endCount++;
		if( endCount < 2 ) {
			return;
		}

		// unbind transition end
		$image.off('.banner');
		_c.$doc.trigger('canclick');
		_c.$doc.trigger('canscrollend');

		// bind scroll listener
		bindScrollListener();
	});

	_c.$doc.one('ready.banner', function() {
		setTimeout(function() {
			$section.addClass('active');
		}, 1000);
	});
});
