
(function(factory) {
	'use strict';

	if( document.querySelector('.home-anchor') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	function onClick(e) {
		e.preventDefault();

		var val = _c.$(e.currentTarget).attr('href');
		if( val.indexOf('?') > -1 ) {
			val = '#' + val.split('?')[1];
		}
		var _top = _c.$(val).offset().top;
		var diff = Math.abs( window.pageYOffset - _top );
		_c.$('html, body').stop().animate({
			scrollTop : _top
		}, diff < 1000 ? 1000 : diff);
	}

	_c.$html.on('click', '.home-anchor', onClick);
});
