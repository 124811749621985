(function(factory) {
	'use strict';
	if( document.querySelector('.button-square') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// functions
	function addSVG(i, element) {
		_c.$(element).prepend([
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193 193">',
			'<rect x="1" y="1" width="191" height="191" stroke-miterlimit="10" stroke-width="2"/>',
			'</svg>'].join('')
		);
	}

	function onReady() {
		_c.$('.button-square').each(addSVG);
	}

	function onClick(e) {
		e.preventDefault();
		var $active = _c.$('.section.active');
		if( ! $active.length ) {
			$active = _c.$(e.currentTarget).closest('.section');
		}
		var $next = $active.next();
		_c.$('html').stop().animate({
			scrollTop : $next.offset().top
		});
	}

	// exec
	_c.$doc.one('ready.links', onReady);
	_c.$html.on('click', '.button-square', onClick);
});
