(function(factory) {
	'use strict';
	if( document.querySelector('.calendar') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	//globals
	var wp = window.wp;
	var moment = window.moment;
	var Backbone = window.Backbone;

	var template = document.querySelector('#calendar-template');
	var calendar;

	// functions
	function getEvents() {

		wp.api.loadPromise.done(function() {
			var collection = Backbone.Collection.extend({
				url : window.location.protocol + '//' + window.location.host + '/wp-json/riverline/v2/events/',
			});
			new collection().fetch().then(function(events) {
				var array = [];
				events.forEach(function(evt) {
					var startDate = moment(event.event_start_date);
					var endDate = moment(event.event_end_date);
					var object = {
						url : evt.url,
					};
					// console.log( startDate, endDate, startDate.isSame( endDate, 'day' ) );
					if( startDate.isSame( endDate, 'day' ) ) {
						object.date = startDate;
					} else {
						object.start = startDate;
						object.end = endDate;
					}
					array.push(object);
				});
				// console.log(array);
				calendar.addEvents(array);
			});
		});
	}

	function initCalendar() {
		calendar = _c.$('.calendar').clndr({
			template       : template.innerText,
			multiDayEvents : {
				endDate   : 'end',
				singleDay : 'date',
				startDate : 'start'
			},
			clickEvents : {
				click : function(object) {
					// console.log(object);
					var evt = object.events[0];
					window.location.href = evt.url;
				}
			}
		});

		// get events
		getEvents();
	}

	function onReady() {
		initCalendar();
	}

	// exec
	_c.$doc.one('ready.calendar', onReady);
});
