
(function(factory) {
	'use strict';

	if( document.querySelector('.main > .section') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var ScrollMagic = window.ScrollMagic;
	var controller  = _c.controller;
	var $sections   = _c.$('.main > .section:not(.section-boxes)');

	function deactivateSection(index, element) {
		// remove the primary class
		var $section = _c.$(element);
		$section.removeClass('active');

		// close the overlay
		var $overlay = $section.find('.section-overlay');
		if( $overlay.length ) {
			$overlay.addClass('closed');
		}
	}

	function activateSection($section) {
		// add the primary class
		$section.addClass('active');

		// open the overlay
		var $overlay = $section.find('.section-overlay.closed');
		if( $overlay.length ) {
			$overlay.removeClass('closed');
		}
	}

	function setActiveSection($section) {
		$section.siblings('.active').each(deactivateSection);
		activateSection($section);
	}

	function createSceneForSection(index, target) {
		var $target = _c.$(target);
		new ScrollMagic.Scene({
			triggerElement : target,
			duration       : target.clientHeight,
		})
		.on('enter', function() {
			setActiveSection( $target );
		})
		.addTo(controller);
	}

	function initScrollMagic() {
		$sections.each(createSceneForSection);
	}

	// trigger on init
	_c.$doc.one('ready.sections', initScrollMagic);
});
