
(function(factory) {
	'use strict';
	if( document.querySelector('[data-video]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// vars
	var Bideo = window.Bideo;

	function getSrc(src) {
		var srcArray = src.split('.');
		var extension = srcArray[srcArray.length - 1];
		var sources = {
			'video/webm' : src.replace(extension, 'webm'),
			'video/ogg'  : src.replace(extension, 'ogv'),
			'video/mp4'  : src.replace(extension, 'mp4'),
		};
		var output = [];
		for(var k in sources) {
			var v = sources[k];
			output.push({
				src  : v,
				type : k
			});
		}
		return output;
	}

	function getSize() {
		var width = 1227;
		var height = 690;
		var wWidth = window.innerWidth;
		var wHeight = window.innerHeight;
		var ar = width / height;
		var vHeight = wHeight;
		var vWidth = vHeight * ar;
		if( wWidth > wHeight ) {
			ar = height / width;
			vWidth = wWidth;
			vHeight = vWidth * ar;
		}
		return {
			height : vHeight,
			width  : vWidth
		};
	}

	function setSize($element) {
		var size = getSize();
		$element
			.attr(size)
			.css(size);
	}

	function init(element) {
		var $element = _c.$(element);
		var $parent = $element.parent();
		var source = getSrc( $element.data('video') );
		var bv = new Bideo();
		setSize($element);
		bv.init({
			videoEl   : element,
			container : $parent[0],
			resize    : true,
			autoplay  : false,
			isMobile  : window.matchMedia('(max-width: 768px)').matches,
			src       : source,
			onLoad    : function () {
				$element.prev('img').remove();
			}
		});
	}

	function onResizeEnd() {
		var videos = document.querySelectorAll('[data-video]');
		if( videos ) {
			Array.prototype.forEach.call(videos, function() {
				setSize( _c.$(this) );
			});
		}
	}

	_c.$doc.one('ready.video', function() {
		var videos = document.querySelectorAll('[data-video]');
		if( videos ) {
			Array.prototype.forEach.call(videos, init);
		}
	});
	_c.$(window).on('resizeend.video', onResizeEnd);
});
