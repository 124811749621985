(function(factory) {
	'use strict';
	if( document.querySelector('.page-filter') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// elements
	var $buttons = _c.$('.page-filter .button');

	function showListView() {
		_c.$('.post-preview').each(function() {
			var $target = _c.$(this).find('.post-preview-header').nextAll();
			$target.slideUp();
		});
	}

	function hideListView() {
		_c.$('.post-preview').each(function() {
			var $target = _c.$(this).find('.post-preview-header').nextAll();
			$target.slideDown();
		});
	}

	function onClick(e) {
		e.preventDefault();

		var $button = _c.$(e.currentTarget);
		var view = $button.data('view');

		$buttons.removeClass('chosen');
		$button.addClass('chosen');

		if( view === 'list' ) {
			showListView();
		} else {
			hideListView();
		}
	}

	// exec
	_c.$('.page-filter').on('click.events', '.button:not(.chosen)', onClick);
});
