
(function(_c) {
	'use strict';

	// globals
	var loadCSS = window.loadCSS;
	var css = [
		'https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css',
	];

	function loadStyles() {
		css.forEach(function(src) {
			loadCSS(src);
		});
	}

	_c.$doc.one('ready.async', function() {
		setTimeout(loadStyles, 1000);
	});

})(window.Clique);
