
(function(factory) {
	'use strict';
	if(document.getElementById('map-area')) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// globals
	var google = window.google;
	var queue = window.queue;
	var controller = _c.MapController;

	// elements
	var header = document.querySelector('.header');
	var container = document.getElementById('map-area');
	var $container = _c.$(container);

	// vars
	var map;
	var locations;
	var iconRadius = 13;
	var activeTooltips = [];
	// var openToolTip = false;
	var locationHash = {};

	function getBoundsZoomLevel(bounds, mapDim) {
		var WORLD_DIM = {
			height : 256,
			width  : 256
		};
		var ZOOM_MAX = 21;

		function latRad(lat) {
			var sin = Math.sin(lat * Math.PI / 180);
			var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
			return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
		}

		function zoom(mapPx, worldPx, fraction) {
			return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
		}

		var ne = bounds.getNorthEast();
		var sw = bounds.getSouthWest();

		var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

		var lngDiff = ne.lng() - sw.lng();
		var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

		var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
		var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

		return Math.min(latZoom, lngZoom, ZOOM_MAX);
	}

	function closeMarkers() {
		if( ! activeTooltips.length ) {
			return;
		}
		var $tooltip = activeTooltips.shift();
		$tooltip.one(_c.support.transition.end, function() {
			_c.$(this).remove();
			// openToolTip = false;
		});
		$tooltip.removeClass('tooltipster-show');
	}

	function markerClick(coords, data) {

		// close all windows
		closeMarkers();

		// create tooltip
		var html = ['<div class="tooltipster-base tooltipster-sidetip tooltipster-shadow tooltipster-custom tooltipster-fade tooltipster-right map-tooltip">',
			'<div class="tooltipster-box">',
				'<div class="tooltipster-content">',
					'<h4>' + data.title + '</h4>',
					data.content,
				'</div>',
			'</div>',
			'<div class="tooltipster-arrow">',
				'<div class="tooltipster-arrow-uncropped">',
					'<div class="tooltipster-arrow-border"></div>',
					'<div class="tooltipster-arrow-background"></div>',
				'</div>',
			'</div>',
		'</div>'].join('');
		var $tooltip = _c.$(html);
		if( !! data.image ) {
			$tooltip.find('.tooltipster-content').prepend('<img src="' + data.image + '" alt="' + data.title + '">');
		}

		var $img = $tooltip.find('img');

		function cb() {

			// set the style
			$tooltip.css({
				top  : coords.y - $tooltip.outerHeight() / 2,
				left : coords.x
			});

			// bind transition end listener
			// $tooltip.one(_c.support.transition.end, function() {
			// 	openToolTip = true;
			// });

			// show the tooltip
			$tooltip.addClass('tooltipster-show');
		}

		// create jquery object and append to the map
		if( $img.length ) {
			$img.one('load', cb);
			$container.append($tooltip);
		} else {
			$container.append($tooltip);
			setTimeout(cb, 0);
		}

		activeTooltips.push($tooltip);
	}

	function preloadImages() {
		var q = queue();
		locations.forEach(function(_location) {
			if( ! _location.image ) {
				return;
			}
			q.defer(function(src, callback) {
				var img = new Image();
				img.onload = function() {
					callback(null);
				};
				img.src = src;
			}, _location.image);
		});
		q.awaitAll(function(error) {
			if(error) {
				throw error;
			}
		});
	}

	function resetMap(bounds) {

		// update map center
		map.setCenter( bounds.getCenter() );

		// update map zoom
		var zoom = getBoundsZoomLevel(bounds, {
			width  : container.clientWidth,
			height : container.clientHeight - (header.clientHeight + 30),
		});
		map.setZoom( zoom );

		google.maps.event.trigger(map, 'resize');
	}

	function getLocationCategories(_location) {
		return _location.category.split(',').map(function(cat) {
			return parseInt(cat, 10);
		});
	}

	function onUpdate(e, category) {
		if( ! locations || ! locations.length ) {
			return;
		}

		// close tooltips
		closeMarkers();

		// set bounds
		var bounds = new google.maps.LatLngBounds();
		locations.forEach(function(_location) {
			var marker = _location.marker;
			if( _location.categories.indexOf(category) > -1 ) {
				marker.setVisible(true);
				var position = marker.getPosition();
				bounds.extend(position);
			} else {
				marker.setVisible(false);
			}
			_location.marker = marker;
		});

		// redraw map
		resetMap(bounds);
	}

	function bindSingleClick(id) {
		_c.$('#map-area').on('click', '[title="' + id + '"]', function() {
			var $area = _c.$(this);
			var $parent = $area.closest('div');
			var title = $area.attr('title');
			if( ! title || ! locationHash[title] ) {
				return;
			}
			var _top = $parent.offset().top;
			var left = $parent.offset().left;
			var _location = locationHash[title];
			markerClick({
				x : left,
				y : _top
			}, _location);
		});
	}

	function addLocations() {

		var currentCategory = _c.$doc.data('location_category');

		// set marker icon and shape
		var symbol = {
			anchor        : new google.maps.Point(iconRadius, iconRadius),
			fillColor     : '#fff',
			fillOpacity   : 1,
			path          : 'M5,13a8,8 0 1,0 16,0a8,8 0 1,0 -16,0',
			strokeColor   : '#fff',
			strokeOpacity : 0.5,
			strokeWeight  : 10
		};
		var shape = {
			coords : [iconRadius, iconRadius, iconRadius],
			type   : 'circle'
		};

		var bounds = new google.maps.LatLngBounds();
		locations.forEach(function(_location) {
			var latlng = new google.maps.LatLng(_location.lat, _location.lng);

			// set location properties
			var id = _c.utils.uid();

			// extend map bounds
			_location.categories = getLocationCategories(_location);
			var visible = _location.categories.indexOf(currentCategory) > -1;
			if( visible ) {
				bounds.extend(latlng);
			}

			// add location marker
			var marker = new google.maps.Marker({
				position  : new google.maps.LatLng(_location.lat, _location.lng),
				map       : map,
				clickable : true,
				icon      : symbol,
				shape     : shape,
				visible   : visible,
				title     : _location.title,
			});

			_location.marker = marker;
			bindSingleClick(id);
			locationHash[id] = _location;
		});

		// preload images after map changes are in place
		var listener = map.addListener('zoom_changed', function() {
			google.maps.event.removeListener(listener);
			setTimeout(preloadImages, 1500);
		});

		// set map size
		resetMap(bounds);
	}

	function getLocations() {
		controller.getLocations().then(function(_locations) {
			locations = _locations.filter(function(_location) {
				return !! _location.category;
			});

			// place on map
			addLocations();
		});
	}

	function onMapClick(e) {
        // console.log( _c.$(e.target) );
		var isMarker = !!( _c.$(e.target).is('area[title]') || _c.$(e.target).closest('[title].gmnoprint').length );
		console.log( isMarker );
		if( ! isMarker && activeTooltips.length ) {
			closeMarkers();
		}
	}

	function initMap() {
		map = controller.createMap(container);

		var listener = map.addListener('tilesloaded', function() {
			google.maps.event.removeListener(listener);
			getLocations();
		});
	}

	// bind click handler
	_c.$html.on('click', '#map-area', onMapClick);

	// init
	controller.ready( initMap );

	// bind category update listener
	_c.$doc.on('map_update', onUpdate);
});
