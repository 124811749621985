
(function(_c) {
	'use strict';

	// based on easing equations from Robert Penner (http://www.robertpenner.com/easing)
	var baseEasings = {};

	_c.$.each( [ "Quad", "Cubic", "Quart", "Quint", "Expo" ], function( i, _name ) {
		baseEasings[ _name ] = function( p ) {
			return Math.pow( p, i + 2 );
		};
	});

	_c.$.extend( baseEasings, {
		Sine : function( p ) {
			return 1 - Math.cos( p * Math.PI / 2 );
		},
		Circ : function( p ) {
			return 1 - Math.sqrt( 1 - p * p );
		},
		Elastic : function( p ) {
			return p === 0 || p === 1 ? p :
				-Math.pow( 2, 8 * (p - 1) ) * Math.sin( ( (p - 1) * 80 - 7.5 ) * Math.PI / 15 );
		},
		Back : function( p ) {
			return p * p * ( 3 * p - 2 );
		},
		Bounce : function( p ) {
			var pow2,
				bounce = 4;

			while( p < ( ( pow2 = Math.pow( 2, --bounce ) ) - 1 ) / 11 ) {}
			return 1 / Math.pow( 4, 3 - bounce ) - 7.5625 * Math.pow( ( pow2 * 3 - 2 ) / 22 - p, 2 );
		}
	});

	_c.$.each( baseEasings, function( _name, easeIn ) {
		_c.$.easing[ "easeIn" + _name ] = easeIn;
		_c.$.easing[ "easeOut" + _name ] = function( p ) {
			return 1 - easeIn( 1 - p );
		};
		_c.$.easing[ "easeInOut" + _name ] = function( p ) {
			return p < 0.5 ?
				easeIn( p * 2 ) / 2 :
				1 - easeIn( p * -2 + 2 ) / 2;
		};
	});

	_c.$.scrollTo = function(position, options, callback) {

		var positionType = typeof position;
		if( positionType === 'undefined' ) {
			return;
		}

		// validate options
		if(typeof options === 'function') {
			options = { callback : options };
		} else if( options === undefined ) {
			options = {};
		}

		// validate callback
		callback = callback || function() {};

		// merge options with default
		options = _c.$.extend(true, {
			offset   : 0,
			easing   : 'easeInOutCubic',
			complete : callback,
		}, options);

		// set position
		var start = window.pageYOffset;
		var to    = start;
		if(positionType === 'number') {
			to = position;
		} else if( _c.$(position).length ) {
			to = _c.$(position).offset().top;
		} else if(positionType === 'string') {
			if( ! isNaN( parseFloat( position ) ) ) {
				to = start + parseFloat( position );
			} else {
				to = start;
			}
		}

		if( to === start ) {
			return;
		}

		// normalize the 'to' value
		to += options.offset;
		to = Math.min( to, _c.$('body').outerHeight() - window.innerHeight );
		delete options.offset;

		// animate
		_c.$('html, body').stop(true).animate({
			scrollTop : to
		}, options);

		// bind scroll listener to kill animation
		setTimeout(function() {
			_c.$(window).one('mousewheel', function() {
				_c.$body.stop(true);
			});
		}, 0);
	};

})(window.Clique);
