// https://d3js.org/d3-queue/ Version 3.0.3. Copyright 2016 Mike Bostock.
(function (global, factory) {
	"use strict";
	typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
	typeof define === 'function' && define.amd ? define(['exports'], factory) :
	(factory((global.d3 = global.d3 || {})));
}(this, (function (exports) { 'use strict';

var slice = [].slice;

var noabort = {};

function Queue(size) {
	if (!(size >= 1)) throw new Error;
	this._size = size;
	this._call =
	this._error = null;
	this._tasks = [];
	this._data = [];
	this._waiting =
	this._active =
	this._ended =
	this._start = 0; // inside a synchronous task callback?
}

Queue.prototype = queue.prototype = {
	constructor: Queue,
	defer: function(callback) {
		if (typeof callback !== "function" || this._call) throw new Error;
		if (this._error != null) return this;
		var t = slice.call(arguments, 1);
		t.push(callback);
		++this._waiting, this._tasks.push(t);
		poke(this);
		return this;
	},
	abort: function() {
		if (this._error == null) abort(this, new Error("abort"));
		return this;
	},
	await: function(callback) {
		if (typeof callback !== "function" || this._call) throw new Error;
		this._call = function(error, results) { callback.apply(null, [error].concat(results)); };
		maybeNotify(this);
		return this;
	},
	awaitAll: function(callback) {
		if (typeof callback !== "function" || this._call) throw new Error;
		this._call = callback;
		maybeNotify(this);
		return this;
	}
};

function poke(q) {
	if (!q._start) {
		try { start(q); } // let the current task complete
		catch (e) {
			if (q._tasks[q._ended + q._active - 1]) abort(q, e); // task errored synchronously
			else if (!q._data) throw e; // await callback errored synchronously
		}
	}
}

function start(q) {
	while (q._start = q._waiting && q._active < q._size) {
		var i = q._ended + q._active,
				t = q._tasks[i],
				j = t.length - 1,
				c = t[j];
		t[j] = end(q, i);
		--q._waiting, ++q._active;
		t = c.apply(null, t);
		if (!q._tasks[i]) continue; // task finished synchronously
		q._tasks[i] = t || noabort;
	}
}

function end(q, i) {
	return function(e, r) {
		if (!q._tasks[i]) return; // ignore multiple callbacks
		--q._active, ++q._ended;
		q._tasks[i] = null;
		if (q._error != null) return; // ignore secondary errors
		if (e != null) {
			abort(q, e);
		} else {
			q._data[i] = r;
			if (q._waiting) poke(q);
			else maybeNotify(q);
		}
	};
}

function abort(q, e) {
	var i = q._tasks.length, t;
	q._error = e; // ignore active callbacks
	q._data = undefined; // allow gc
	q._waiting = NaN; // prevent starting

	while (--i >= 0) {
		if (t = q._tasks[i]) {
			q._tasks[i] = null;
			if (t.abort) {
				try { t.abort(); }
				catch (e) { /* ignore */ }
			}
		}
	}

	q._active = NaN; // allow notification
	maybeNotify(q);
}

function maybeNotify(q) {
	if (!q._active && q._call) {
		var d = q._data;
		q._data = undefined; // allow gc
		q._call(q._error, d);
	}
}

function queue(concurrency) {
	return new Queue(arguments.length ? +concurrency : Infinity);
}
// console.log(queue);

window.queue = queue;

// exports.queue = queue;

// Object.defineProperty(exports, '__esModule', { value: true });

})));
