
(function(factory) {
	'use strict';

	if( document.querySelector('textarea') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var events = [
		'change',
		'cut',
		'paste',
		'drop',
		'keyup',
		'keydown',
	];
	var isActive = false;

	function getStyles() {
		_c.$('textarea').each(function() {
			var $element = _c.$(this);
			var style    = $element.css('font');
			$element.data({
				font : style
			});
		});
	}

	function callback(e) {
		var textarea   = e.target;
		var $textarea  = _c.$(textarea);
		var height     = textarea.clientHeight;
		var textHeight = textarea.scrollHeight - parseFloat( $textarea.css('border-bottom-width') );
		if( textHeight <= height || isActive ) {
			return;
		}
		isActive = true;
		$textarea.one(_c.support.transition.end, function() {
			isActive = false;
		});

		var newHeight = textHeight - parseFloat( $textarea.css('padding-top') ) - parseFloat( $textarea.css('padding-bottom') ) + parseFloat( $textarea.css('border-bottom-width') );
		$textarea.height( newHeight );
	}

	function initTextareas() {
		getStyles();
		_c.$html.on(events.join(' '), 'textarea', callback);
	}

	_c.$doc.one('ready.textarea', initTextareas);
});
