
// scrollend
(function( global, factory ) {
	'use strict';

	if( typeof window === "undefined" && global.window ) {
		global = global.window;
	}

	var component;
	if(global.Clique) {
		component = factory(global.Clique, global);
	} else if( typeof define === 'function' && define.amd ) {
		define('clique-events', ['clique'], function() {
			return component || factory(global.Clique, global);
		});
	}

})(typeof window !== "undefined" ? window : this, function( _c, win ) {
	'use strict';

	_c.events = _c.$.extend({}, _c.events, {

		// @name: Clique.events.scrollend
		// @description: Triggers an event when a user stops scrolling
		// @since: 1.0.0-beta
		// @todo: Add `properties` as a default option to list of values
		// @todo: Value would be array of animation-properties (as strings) to listen for.
		scrollend : {
			latency : 150,
			setup   : function(data) {

				data = _c.$.extend({
					latency : _c.$.event.special.scrollend.latency
				}, data);

				var uid = _c.utils.uid('scrollend'),
					timer = null,
					handler = function(e) {
						if(timer) {
							win.clearTimeout(timer);
						}
						timer = win.setTimeout(function() {
							timer = null;
							var target = _c.$(e.target);
							return target.trigger('scrollend', [{
								top  : target.scrollTop(),
								left : target.scrollLeft(),
							}]);
						}, data.latency);
					};

				_c.$(this).data('clique.event.scrollend.uid', uid);
				return _c.$(this).on('scroll', _c.utils.debounce(handler, 100)).data(uid, handler);
			},
			teardown : function() {
				var uid = _c.$(this).data('clique.event.scrollend.uid');
				_c.$(this).off('scroll', _c.$(this).data(uid));
				_c.$(this).removeData(uid);
				return _c.$(this).removeData('clique.event.scrollend.uid');
			}
		},

	});

	// Register events
	(function() {

		_c.$.event.special.scrollend = _c.events.scrollend;
		var fn = function(options, callback) {
			return this.each(function() {
				return _c.$(this).on('scrollend', options, callback);
			});
		};
		_c.$.fn.scrollend = fn;

	})();
});

// scrollstart
(function( global, factory ) {
	'use strict';

	if( typeof window === "undefined" && global.window ) {
		global = global.window;
	}

	var component;
	if(global.Clique) {
		component = factory(global.Clique);
	} else if( typeof define === 'function' && define.amd ) {
		define('clique-events', ['clique'], function() {
			return component || factory(global.Clique);
		});
	}

})(typeof window !== "undefined" ? window : this, function( _c ) {
	'use strict';

	_c.events = _c.$.extend({}, _c.events, {

		// @name: Clique.events.scrollstart
		// @description: Triggers an event when a user begins scrolling
		// @since: 1.0.0-beta
		// @todo: Add `properties` as a default option to list of values
		// @todo: Value would be array of animation-properties (as strings) to listen for.
		scrollstart : {
			setup : function() {
				var uid = _c.utils.uid('scrollstart'),
					handler = function(e) {

						e.type = 'scrollstart.clique.dom';
						_c.$(e.target).trigger('scrollstart', [{
							top  : _c.$(e.target).scrollTop(),
							left : _c.$(e.target).scrollLeft(),
						}]);
					};
				_c.$(this).on('scrollstart', function() {
					return _c.$(this).off('scroll', handler);
				});
				_c.$(this).on('scrollend', function() {
					return _c.$(this).on('scroll', handler).data(uid, handler);
				});
				_c.$(this).data('clique.event.scrollstart.uid', uid);
				return _c.$(this).on('scroll', handler).data(uid, handler);
			},
			teardown : function() {
				var uid = _c.$(this).data('clique.event.scrollstart.uid');
				_c.$(this).off('scroll', _c.$(this).data(uid));
				_c.$(this).removeData(uid);
				return _c.$(this).removeData('clique.event.scrollstart.uid');
			}
		},

	});

	// Register events
	(function() {

		_c.$.event.special.scrollstart = _c.events.scrollstart;
		var fn = function(options, callback) {
			return this.each(function() {
				return _c.$(this).on('scrollstart', options, callback);
			});
		};
		_c.$.fn.scrollstart = fn;

	})();
});



// resizeend
(function( global, factory ) {
	'use strict';

	if( typeof window === "undefined" && global.window ) {
		global = global.window;
	}

	var component;
	if(global.Clique) {
		component = factory(global.Clique, global);
	}

	if( typeof define === 'function' && define.amd ) {
		define('clique-events', ['clique'], function() {
			return component || factory(global.Clique, global);
		});
	}

})(typeof window !== "undefined" ? window : this, function( _c, win ) {
	'use strict';

	_c.events = _c.$.extend({}, _c.events, {

		// @name: Clique.events.resizeend
		// @description: Triggers an event when a user ends resizing the bound element (typically the window)
		// @since: 1.0.0-beta
		resizeend : {
			latency : 250,
			setup   : function(data) {
				var uid = _c.utils.uid('resizeend'),
					_data = _c.$.extend({
						latency : _c.$.event.special.resizeend.latency
					}, data),
					timer,
					handler = function(e) {
						if(timer) {
							win.clearTimeout(timer);
						}
						timer = win.setTimeout(function() {
							timer = null;
							var target = _c.$(e.target);
							return target.trigger('resizeend', [{
								width  : target.width(),
								height : target.height(),
							}]);
						}, _data.latency);
					};
				_c.$(this).data('clique.event.resizeend.uid', uid);
				_c.$(this).on('resizeend', function() {
					return _c.$(this).off('resize', _c.utils.debounce(handler, 100));
				});
				return _c.$(this).on('resizestart', function() {
					return _c.$(this).on('resize', _c.utils.debounce(handler, 100)).data(uid, handler);
				});
			},
			teardown : function() {
				var uid = _c.$(this).data('clique.event.resizeend.uid');
				_c.$(this).off('resize', _c.$(this).data(uid));
				_c.$(this).removeData(uid);
				return _c.$(this).removeData('clique.event.resizeend.uid');
			}
		},

	});

	// Register events
	(function() {

		_c.$.event.special.resizeend = _c.events.resizeend;
		var fn = function(options, callback) {
			return this.each(function() {
				return _c.$(this).on('resizeend', options, callback);
			});
		};
		_c.$.fn.resizeend = fn;

	})();
});

// resizestart
(function( global, factory ) {
	'use strict';

	if( typeof window === "undefined" && global.window ) {
		global = global.window;
	}

	var component;
	if(global.Clique) {
		component = factory(global.Clique);
	}

	if( typeof define === 'function' && define.amd ) {
		define('clique-events', ['clique'], function() {
			return component || factory(global.Clique);
		});
	}

})(typeof window !== "undefined" ? window : this, function( _c ) {
	'use strict';

	_c.events = _c.$.extend({}, _c.events, {

		// @name: Clique.events.resizestart
		// @description: Triggers an event when a user begins resizing the bound element (typically the window)
		// @since: 1.0.0-beta
		resizestart : {
			setup : function() {
				var ele = _c.$(this),
					uid = _c.utils.uid('resizestart'),
					handler = function(e) {
						var target = _c.$(e.target);
						return target.trigger('resizestart', [{
							width  : target.width(),
							height : target.height(),
						}]);
					};
				ele.data('clique.event.resizestart.uid', uid);
				ele.on('resizestart', function() {
					return _c.$(this).off('resize', handler);
				});
				ele.on('resizeend', function() {
					return _c.$(this).on('resize', handler).data(uid, handler);
				});
				return ele.on('resize', handler).data(uid, handler);
			},
			teardown : function() {
				var uid = _c.$(this).data('clique.event.resizestart.uid');
				_c.$(this).off('resize', _c.$(this).data(uid));
				_c.$(this).removeData(uid);
				return _c.$(this).removeData('clique.event.resizestart.uid');
			}
		},

	});

	// Register events
	(function() {

		_c.$.event.special.resizestart = _c.events.resizestart;
		var fn = function(options, callback) {
			return this.each(function() {
				return _c.$(this).on('resizestart', options, callback);
			});
		};
		_c.$.fn.resizestart = fn;

	})();
});
