
(function(factory) {
	'use strict';

	if( document.querySelector('.section-stats') && ! window.Clique.support.hasTouch ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var ScrollMagic = window.ScrollMagic;
	var controller  = _c.controller;
	var $rows       = _c.$('.section-stats > .container > .row');

	function initialSetup($row) {
		$row.find('.content > *').each(function(i) {
			if( ! i ) {
				return;
			}

			var $item = _c.$(this);
			var delay = parseFloat( $item.css('transition-delay').replace('s', '') );
			var newDelay = delay + i * 0.1;

			// set new transition delay value
			$item.css({
				'transition-delay' : newDelay + 's',
			});
		});
	}

	function setImageTransform($img, progress) {
		var y = -35 + 70 * progress;
		$img.css({
			transform : 'translate3d(0, ' + y + 'px, 0)'
		});
	}

	function createSceneForSection(index, target) {
		var $row     = _c.$(target);
		var $img     = $row.find('img');

		initialSetup( $row );

		var scene = new ScrollMagic.Scene({
			triggerElement : target,
			duration       : target.clientHeight,
		})
		.on('enter', function() {
			$row.addClass('visible');
			scene.off('enter');
		})
		.on('progress', function(e) {
			setImageTransform( $img, e.progress );
		})
		.addTo(controller);
	}

	function initScrollMagic() {
		$rows.each(createSceneForSection);
	}

	_c.$doc.one('canscrollend.stats', initScrollMagic);
});
