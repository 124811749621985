
(function(factory) {
	'use strict';

	if( document.querySelector('[data-tooltip-content]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// vars
	var $circles = _c.$('[data-tooltip-content]');
	var width = 436;

	function removeActive() {
		$circles.removeClass('active');
	}

	function setActive(element) {
		var $circle = _c.$(element);

		// guards
		if( $circle.hasClass('active') ) {
			return;
		}

		// remove existing active
		removeActive();

		// set new active state
		$circle.addClass('active');
	}

	function setupTooltips() {
		$circles.each(function() {
			var $circle = _c.$(this);
			var id = $circle.data('tooltip-content');
			var $target = _c.$( id );
			var $clone = $target.clone();

			if( ! $target.length ) {
				return;
			}

			var $wrapper = $clone.wrap('<div class="tooltipster-content" />').parent();
			var $tooltip = $wrapper.wrap('<div class="tooltipster-box" />').parent();
			var $package = $tooltip.wrap('<div class="tooltipster-base tooltipster-sidetip tooltipster-shadow tooltipster-custom tooltipster-right tooltipster-fade tooltipster-show tooltipster-clone" style="width:' + width + 'px;" />').parent();
			_c.$body.append($package);
			var height = $package.find(id).outerHeight();
			$package.remove();
			$target.css({
				'min-height' : height
			});

			$circle.tooltipster({
				contentCloning : true,
				// trigger        : 'click',
                trigger        : _c.support.hasTouch ? 'click' : 'custom',
                //trigger: 'custom',
                triggerOpen: {
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    mouseleave: true,
                    touchleave: true
                },
				interactive    : true,
				minWidth       : width,
				maxWidth       : width,
				side           : 'right',
				theme          : [
					'tooltipster-shadow',
					'tooltipster-custom',
				],
				functionBefore : function(instance, helper) {
					setActive( helper.origin );
				},
				functionAfter : function(instance) {
					_c.$( instance.elementOrigin() ).removeClass('active');
				}
			});
		});
	}

	// init
	_c.$doc.one('ready.tooltips', setupTooltips);
});
