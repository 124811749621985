
(function(factory) {
	'use strict';

	if( document.querySelector('.header') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var ScrollMagic = window.ScrollMagic;
	var controller  = _c.controller;
	var $sections   = _c.$('.main > .section');
	var $header     = _c.$('.header');
	var height      = $header[0].clientHeight;
	var offset      = window.innerHeight - height / 2;

	function setHeaderClass( $section ) {
		var isLight = $section.data('isLight');
		if( typeof isLight === 'undefined' ) {
			return;
		} else if( isLight ) {
			$header.addClass('header-dark');
		} else if( $header.hasClass('header-dark') ) {
			$header.removeClass('header-dark');
		}
	}

	function updateValues() {
		height = document.querySelector('.header').clientHeight;
		offset = window.innerHeight - height / 2;
	}

	function bindScrollListener(i, element) {
		var target = element;

		// update all values
		updateValues();

		new ScrollMagic.Scene({
			triggerElement : target,
			triggerHook    : 'onEnter',
			duration       : target.clientHeight,
			offset         : offset
		})
		.on('enter', function() {
			setHeaderClass( _c.$(target) );
		})
		.addTo(controller);
	}

	function initScrollMagic() {
		$header.addClass('active');
		$sections.each(bindScrollListener);
	}
	// trigger on init
	//_c.$doc.on('ready.header', initScrollMagic);
    
    window.onscroll = function() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if(scrollTop > 1) {
            _c.$('.home .header').addClass('scroll');
        } else {
            _c.$('.home .header').removeClass('scroll');
        }
    }
});
