(function(factory) {
	'use strict';
	if( document.querySelector('[data-accordion]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// elements
	var $accordion = _c.$('[data-accordion]');

	// functions
	function setChildHeights() {
		$accordion.find('> li').each(function(_i) {
			var $item = _c.$(this);
			$item.wrapInner('<div class="accordion-guard" />');
			var height = $item.find('.accordion-guard').outerHeight();
			$item.data('height', height);
			if( _i ) {
				$item.addClass('closed').height(60);
			} else {
				$item.height(height);

				// trigger inital category
				updateCategory( $item );
			}
		});

		// set ready class
		$accordion.closest('.map-overlay').addClass('ready');
	}

	function updateCategory($item) {
		var category = $item.data('location-category');
		_c.$doc.data('location_category', category);
		_c.$doc.trigger('map_update', [category]);
	}

	function onLoad() {
		setChildHeights();
	}

	function onClick(e) {

		// vars
		var $item = _c.$(e.currentTarget);
		var height = $item.data('height');

		// update ui
		$item.siblings(':not(.closed)').height(60).addClass('closed');
		$item.height(height).removeClass('closed');

		// trigger event for maps
		updateCategory($item);
	}

	function onReady() {
		var category = $accordion.find('> li:first-child').data('location-category');
		_c.$doc.data('location_category', category);
	}

	// init
	onReady();
	_c.$doc.one('ready.accordion', onLoad);
	_c.$html.on('click.accordion', '[data-accordion] > .closed', onClick);
});
