
(function(factory) {
	'use strict';

	if( document.querySelector('[data-menu-button]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// globals
	var TweenLite = window.TweenLite;

	// vars
	var transitionDelay = 0.1;
	var tw1;
	var tw2;

	// elements
	var $submenu = _c.$('.sub-menu');
	var menuOpen = false;

	// functions
	function setupTimeline() {
		tw1 = TweenLite.to("#first-1", 0.5, {
			morphSVG : "#last-1",
			paused   : true,
		});
		tw2 = TweenLite.to("#first-2", 0.5, {
			morphSVG : "#last-2",
			paused   : true,
		});
	}

	function closeMenu() {
		_c.$('.menu').find('.has-active, .active').each(function() {
			_c.$(this).removeClass('has-active active');
		});
	}

	function bindScroll() {
		if( _c.support.hasTouch ) {
			return;
		}
		_c.$('.menu').off('scrollend.menu');
		_c.$('.menu').on('scrollend.menu', function() {
			var center = this.scrollTop + window.innerHeight / 2;
			$submenu.css({
				top : center
			});
		});
	}

	function onButtonClick(e) {
		e.preventDefault();
		_c.$body.toggleClass('menu-open');
		menuOpen = ! menuOpen;

		if( menuOpen ) {
			tw1.play();
			tw2.play();

			// bind scroll
			bindScroll();
		} else {
			tw1.reverse();
			tw2.reverse();

			// close menu
			closeMenu();
		}
	}

	function onMenuClick(e) {
		e.preventDefault();
		var $li = _c.$(e.target).parent('li');
		var $siblings = $li.siblings();
		var $list = $li.parent('.menu-list');

		if( $li.hasClass('active') ) {
			closeMenu();
			return;
		}

		$list.addClass('has-active');
		$siblings.removeClass('active');
		$li.addClass('active');
	}

	function setTransitionDelay() {
		$submenu.each(function() {
			_c.$(this).find('> li').each(function(i) {
				if( ! i ) {
					return;
				}

				_c.$(this).css({
					'transition-delay' : transitionDelay * i + 's'
				});
			});
		});
	}

	function onReady() {
		setTransitionDelay();
		// bindScroll();
		setupTimeline();
	}

	function onMenuOffClick(e) {
		var $target = _c.$(e.target);
		if( ! $target.closest('.menu-list').length ) {
			closeMenu();
		}
	}

	// exec
	_c.$html.on('click.menu', '[data-menu-button]', onButtonClick);
	_c.$html.on('click.menu', '.menu-list > li > a[href="#"]', onMenuClick);
	_c.$html.on('click.menu', '.menu', onMenuOffClick);
	_c.$doc.one('ready.menu', onReady);
});
