
(function(factory) {
	'use strict';
	if( document.querySelectorAll('.quotes-button-list') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// functions
	function onClick(e) {
		e.preventDefault();

		// vars
		var $target = _c.$(e.target);
		var index = $target.parent().index();
		var $slider = _c.$('.quotes-slider');
		var $li = _c.$('.quotes-list > li');

		// guards
		if( $target.hasClass('active') ) {
			return;
		}

		// set button class
		_c.$('.quotes-button-list a').removeClass('active');
		$target.addClass('active');

		// set list class
		$slider.attr('class').split(' ').forEach(function(cls) {
			if( cls.indexOf('slide-') > -1 ) {
				$slider.removeClass(cls);
			}
		});
		$slider.addClass('slide-' + index);

		// set active li
		$li.removeClass('active-slide');
		$li.eq(index).addClass('active-slide');
	}

	// exec
	// _c.$html.on('click', '.quotes-button-list a', onClick);
	_c.$('.quotes-button-list').on('mouseenter', 'a', onClick);
});
