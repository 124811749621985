
(function(factory) {
	'use strict';

	if( document.querySelector('.home-nav') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var ScrollMagic = window.ScrollMagic;
	var controller  = _c.controller;
	var $sections   = _c.$('.can-scroll > .section:not(.section-boxes)');
	var $nav        = _c.$('.home-nav');
	var $items      = $nav.find('> li:not(.line)');
	var $line       = _c.$('.line');
	var activeIndex = 0;
	var hovering    = false;
	var timeout;

	function setActiveLine(index) {
		var $item = $items.eq(index);
		if( ! $item.length ) {
			return;
		}
		var height    = $item.height();
		var marginTop = parseFloat( $item.css('margin-top') );
		var _top       = parseInt( $item.position().top + marginTop + height / 2, 10 );
		$line.css({
			transform : 'translate(0, ' + _top + 'px)'
		});
	}

	function createSceneForSection(index, target) {
		new ScrollMagic.Scene({
			triggerElement : target,
			duration       : target.clientHeight,
		})
		.on('enter', function() {
			setActiveLine(index);
		})
		.addTo(controller);
	}

	function initScrollMagic() {
		$sections.each(createSceneForSection);
	}

	function createItems() {
		var count = $sections.length;
		var i = 0;
		while(i < count) {
			$nav.append('<li><a href="#">' + (i + 1) + '</a></li>');
			i++;
		}

		// reset items var
		$items = $nav.find('> li:not(.line)');
	}

	function onClick(e) {
		e.preventDefault();

		// guards
		if( ! $sections.length ) {
			_c.$('.main-home').addClass('can-scroll');
			_c.$('.section-banner').trigger('mousewheel');
			$sections = _c.$('.can-scroll > .section:not(.section-boxes)');
			onClick(e);
			return;
		}

		var $link    = _c.$(e.target);
		var index    = $link.closest('li').index() - 1;
		var $section = $sections.eq(index);
		var _top      = $section.offset().top;
		activeIndex = index;
		_c.$('html, body').stop(true).animate({
			scrollTop : _top
		}, 1000);
	}

	function onReady() {
		// refresh variable
		$sections = _c.$('.main > .section:not(.section-boxes)');

		// init
		createItems();
		initScrollMagic();
	}

	// init
	_c.$doc.one('ready.homenav', onReady);
	_c.$html.on('click.homenav', '.home-nav a', onClick);
	_c.$('.home-nav')
	.on('mouseenter', 'a', function(e) {
		hovering = true;
		if( timeout ) {
			clearTimeout(timeout);
		}
		var $target = _c.$(e.currentTarget);
		var index = $target.closest('li').index() - 1;
		setActiveLine(index);
	})
	.on('mouseleave', 'a', function() {
		hovering = false;
		if( timeout ) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(function() {
			if( ! hovering ) {
				setActiveLine(activeIndex);
			}
		}, 250);
	});
});
