
(function(factory) {
	'use strict';

	if( document.querySelector('[data-change-history]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// globals
	var _history = window.history;
	_history.scrollRestoration = "manual";

	// popstate listener
	window.onpopstate = function() {
		_c.$win.trigger('history.push', [window.location.hash]);
	};

	// functions
	function triggerHistoryChange(e) {

		// vars
		var hash = '';

		if( e ) {
			e.preventDefault();
			var $target = _c.$(e.target);
			if( $target.is('a') ) {
				hash = $target.data('change-history');
			} else {
				hash = $target.closest('a').data('change-history');
			}
		} else {
			hash = window.location.href.split('#')[0];
		}

		// update history
		_history.pushState(null, null, hash);

		// trigger custom event
		_c.$win.trigger('history.push', [hash]);
	}

	// bind click handler
	_c.$html.on('click', '[data-change-history]', triggerHistoryChange);

	// globalize function
	_c.changeHistory = triggerHistoryChange;
});
