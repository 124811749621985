
(function(factory) {
	'use strict';

	if( document.querySelector('.section-internal-banner') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var $section = _c.$('.section-internal-banner');
	var $header = _c.$('.header');

	// functions
	function triggerScreenshot() {
		console.log('page_ready');
	}

	function resetBanner() {
		$header.removeClass('header-dark-right header-dark-left');
		$section.removeClass('active-right active-left');
		_c.$('.diagonal-link.active').removeClass('active');
	}

	function setRightLeftActive($parent) {
		var dir = 'left';
		if( $parent.attr('class').indexOf('-right') > -1 ) {
			dir = 'right';
		}
		$section.addClass('active-' + dir);
		$header.addClass('header-dark-' + dir);
	}

	// handlers
	function onLoad() {
		setTimeout(function() {
			$section.addClass('loaded');
			setTimeout(triggerScreenshot, 500);
		}, 1000);
	}

	function onHistory(e, hash) {
		hash = hash || window.location.hash;

		// reset all
		resetBanner();

		// guard 1
		if( ! hash ) {
			return;
		}

		// guard 2
		var $link = _c.$('[data-change-history="' + hash + '"]');
		if( ! $link.length ) {
			return;
		}

		// guard 3
		var $parent = $link.parent('.diagonal-link');
		if( $parent.hasClass('active') ) {
			return;
		}

		// update ui
		_c.$('.diagonal-link.active').removeClass('active');
		$parent.addClass('active');

		// set active direction
		setRightLeftActive($parent);
	}

	function onActiveClick(e) {
		e.stopPropagation();
		_c.changeHistory();
	}

	function onKeyUp(e) {
		if( e.keyCode !== 27 ) {
			return;
		}
		onActiveClick(e);
	}

	// listeners
	// _c.$doc.one('ready.internalbanner', onReady);
	_c.$win.one('load.internalbanner', onLoad);
	_c.$win.on('load history.push', onHistory);
	_c.$doc.on('keyup.internalbanner', onKeyUp);
	_c.$('.diagonal-link-wrapper').on('click', '.diagonal-link.active', onActiveClick);
});
