
(function(factory) {
	'use strict';

	if( document.querySelector('.main') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var ScrollMagic = window.ScrollMagic;
	var controller  = _c.controller;
	var tinycolor   = window.tinycolor;
	var $homeNav    = _c.$('.home-nav');

	function setData($section, index) {

		// determine if it's light or dark
		var backgroundColor = $section.css('background-color');
		var colorObject     = tinycolor(backgroundColor);
		var isLight         = colorObject.isLight();
		if( $section.is('.property-banner') ) {
			isLight = true;
		}
		$section.data({
			isLight : isLight,
			index   : index,
		});
	}

	function setNavClass(isLight) {
		if( isLight ) {
			$homeNav.addClass('dark');
		} else {
			$homeNav.removeClass('dark');
		}
	}

	function setMenuColor(section) {
		var $section = _c.$(section);
		var isLight  = $section.data('isLight');

		// check for existing data
		if( typeof isLight !== 'undefined' ) {
			setNavClass(isLight);
			return;
		}

		// cache value in data
		setData( $section );

		// determine if it's light or dark
		setNavClass(isLight);
	}

	function createSceneForSection(index, target) {

		// cache data on ready
		setData( _c.$(target), index );

		if( ! $homeNav.length ) {
			return;
		}

		// create new scroll scene
		new ScrollMagic.Scene({
			triggerElement : target,
			duration       : target.clientHeight,
		})
		.on('enter', function() {
			if( ! index ) {
				return;
			}
			setMenuColor(target);
		})
		.addTo(controller);
	}

	function initScrollMagic() {
		_c.$('.main > .section').each(createSceneForSection);
	}

	// init
	_c.$doc.one('canscrollend.colorscroll', initScrollMagic);
});
