
(function(factory) {
	'use strict';
	if( document.querySelector('input, select, textarea') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// functions
	function onBlur(e) {
		var $input = _c.$(e.target);
		var $parent = $input.closest('span, div').first();
		$parent.removeClass('focusing');
	}

	function onFocus(e) {
		var $input = _c.$(e.target);
		var $parent = $input.closest('span, div').first();
		$parent.addClass('focusing');
		$input.one('blur', onBlur);
	}

	// exec
	_c.$('form').on('focus', 'input, textarea, select', onFocus);

});
