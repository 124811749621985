
(function(factory) {
	'use strict';

	if( document.querySelector('[data-background]') || document.querySelector('[data-lazy-src]') || document.querySelector('.tooltip-wrapper') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// global vars
	var $backgrounds = _c.$('[data-background]');

	function preloadBackgrounds(i, element) {
		var $element = _c.$(element);
		var src      = $element.data('background');

		// load image async
		var img = new Image();
		img.addEventListener('load', function() {
			var $figure = _c.$('<figure class="section-background" style="background-image:url(' + src + ');opacity:0;"></figure>');
			$element
				.prepend($figure)
				.removeAttr('data-background')
				.removeData('background');

			var timeout = setTimeout(function() {
				clearTimeout(timeout);
				timeout = null;
				$figure.css('opacity', '');
			}, 0);
		});
		img.src = src;
	}

	function preloadImages(i, element) {
		var $element = _c.$(element);
		var src      = element.getAttribute('data-lazy-src');

		// load image async
		var img = new Image();
		img.addEventListener('load', function() {
			$element.removeAttr('data-lazy-src').attr('src', src);
		});
		img.src = src;
	}

	function setTooltipImages() {
		_c.$('.tooltip-wrapper img').each(function() {
			var src = this.src;
			_c.$(this).attr({
				'data-lazy-src' : src,
			}).data({
				'lazy-src' : src
			});
		});
	}

	setTooltipImages();

	_c.$win.one('load.lazyload', function() {
		$backgrounds.each(preloadBackgrounds);
		_c.$('[data-lazy-src]').each(preloadImages);
	});
});
