(function(factory) {
	'use strict';
	if( document.querySelector('.map') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// globals
	var google = window.google;
	var queue = window.queue;
	var Backbone = window.Backbone;

	// vars
	var timeout;

	// create class
	function MapController() {
		this.options = {
			center : {
				lat : 41.8701807,
				lng : -87.6335375,
			},
			zoom                   : 11,
			disableDefaultUI       : true,
			draggable              : _c.support.hasTouch ? false : true,
			disableDoubleClickZoom : true,
			fullscreenControl      : false,
			mapTypeControl         : false,
			panControl             : false,
			rotateControl          : false,
			scaleControl           : false,
			scrollwheel            : false,
			streetViewControl      : false,
			zoomControl            : false,
			styles                 : [{
				"featureType" : "all",
				"elementType" : "labels.text.fill",
				"stylers"     : [{
					"color" : "#ffffff"
				}]
			}, {
				"featureType" : "all",
				"elementType" : "labels.text.stroke",
				"stylers"     : [{
					"visibility" : "on"
				}, {
					"lightness" : "5"
				}, {
					"gamma" : "1"
				}, {
					"color" : "#5a748f"
				}]
			}, {
				"featureType" : "all",
				"elementType" : "labels.icon",
				"stylers"     : [{
					"visibility" : "off"
				}]
			}, {
				"featureType" : "administrative",
				"elementType" : "geometry.fill",
				"stylers"     : [{
					"color" : "#495f75"
				}]
			}, {
				"featureType" : "administrative",
				"elementType" : "geometry.stroke",
				"stylers"     : [{
					"color" : "#fefefe"
				}, {
					"weight" : 1.2
				}]
			}, {
				"featureType" : "landscape",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#495f75"
				}]
			}, {
				"featureType" : "poi",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#495f75"
				}]
			}, {
				"featureType" : "poi.park",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#495f75"
				}]
			}, {
				"featureType" : "road.highway",
				"elementType" : "geometry.fill",
				"stylers"     : [{
					"color" : "#5a748f"
				}]
			}, {
				"featureType" : "road.highway",
				"elementType" : "geometry.stroke",
				"stylers"     : [{
					"lightness" : 29
				}, {
					"weight" : 0.2
				}, {
					"visibility" : "off"
				}]
			}, {
				"featureType" : "road.arterial",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#5a748f"
				}]
			}, {
				"featureType" : "road.local",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#5a748f"
				}]
			}, {
				"featureType" : "transit",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#5a748f"
				}]
			}, {
				"featureType" : "water",
				"elementType" : "geometry",
				"stylers"     : [{
					"color" : "#5a748f"
				}]
			}]
		};
		this.queue = queue();
	}

	MapController.prototype = {
		constructor : MapController,

		ready : function(fn) {
			var _this = this;

			if( timeout ) {
				clearTimeout(timeout);
			}

			if( fn && _c.is.array(fn) ) {
				fn.forEach(function(_fn) {
					_this.ready(_fn);
				});
				return;
			}
			if( fn && typeof fn === 'function' ) {
				this.queue.defer(function(callback) {
					fn();
					callback();
				});
			}

			var cb = function() {
				_this.queue.awaitAll(function(error) {
					if(error) {
						console.warn(error);
						throw error;
					}
					_this.getLocations();
				});
			};

			if( document.readyState === 'complete' && google ) {
				cb();
			} else if( document.readyState === 'complete' && ! google ) {
				timeout = setTimeout(_this.ready, 250);
			} else {
				document.onreadystatechange = function() {
					_this.ready();
				};
			}
		},

		createMap : function(target) {
			return new google.maps.Map(target, this.options);
		},

		getLocations : function() {
			var _this = this;
			return new Promise(function(resolve) {

				if( _this.locations ) {
					resolve(_this.locations);
					return;
				}

				// get locations
				var collection = Backbone.Collection.extend({
					url : window.location.protocol + '//' + window.location.host + '/wp-json/riverline/v2/locations/',
				});
				new collection().fetch().then(function(locations) {
					_this.locations = locations;
					resolve(_this.locations);
				});
			});
		},

		getMapSize : function() {
			return {
				width  : this.container.clientWidth,
				height : this.container.clientHeight,
			};
		},

		getZoom : function(bounds, size) {
			var WORLD_DIM = {
				height : 256,
				width  : 256
			};
			var ZOOM_MAX = 21;

			function latRad(lat) {
				var sin = Math.sin(lat * Math.PI / 180);
				var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
				return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
			}

			function zoom(mapPx, worldPx, fraction) {
				return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
			}

			var ne = bounds.getNorthEast();
			var sw = bounds.getSouthWest();

			var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

			var lngDiff = ne.lng() - sw.lng();
			var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

			var latZoom = zoom(size.height, WORLD_DIM.height, latFraction);
			var lngZoom = zoom(size.width, WORLD_DIM.width, lngFraction);

			return Math.min(latZoom, lngZoom, ZOOM_MAX);
		},
	};

	// add to primary collection
	_c.MapController = new MapController();
});
