
(function ($) {
	'use strict';

	// globals
	var _c = window.Clique = {};

	// cache jquery & dom objects
	_c.$ = $;
	_c.$win = _c.$(window);
	_c.$doc = _c.$(document);
	_c.$html = _c.$(document.documentElement);
	_c.$body = _c.$('body');

	// other globals
	_c.controller  = new window.ScrollMagic.Controller();

	// utility methods
	_c.utils = {

		now : Date.now || function () {
			return new Date().getTime();
		},

		uid : function(prefix) {
			return (prefix || 'id') + _c.utils.now() + 'RAND' + Math.ceil(Math.random() * 100000);
		},

		debounce : function(fn, delay, immediate) {
			var timeout;
			var wait = delay || 0;
			return function() {
				var args = arguments;
				var context = this;
				var later = function() {
					timeout = null;
					if(! immediate) {
						fn.apply(context, args);
					}
				};
				var callNow = immediate && ! timeout;
				if(timeout) {
					window.clearTimeout(timeout);
					timeout = null;
				}
				timeout = window.setTimeout(later, wait);

				if(callNow) {
					fn.apply(context, args);
				}
			};
		},

		prefixFor : function(property) {
			var vendors  = ['Webkit', 'Moz', 'O'];
			var prop     = property[0].toUpperCase() + property.slice(1);
			var path     = document.createElementNS('http://www.w3.org/2000/svg', 'a');
			var style    = path.style;
			var output   = {
				js  : '',
				css : '',
			};

			if( prop.toLowerCase() in style ) {
				return output;
			}

			vendors.forEach(function(item) {
				if(item + prop in style) {
					var vendor = item.toLowerCase();
					output = {
						js  : vendor[0].toUpperCase() + vendor.splice(1),
						css : '-' + vendor + '-',
					};
				}
			});
			return output;
		},

		convertSize : function(int) {
			if(int > 1024 * 1024) {
				return (Math.round(int * 100 / (1024 * 1024)) / 100).toString() + 'MB';
			} else  {
				return (Math.round(int * 100 / 1024) / 100).toString() + 'KB';
			}
		}
	};

	// typecheckers
	_c.is = {

		array : function( obj ) {
			return Object.prototype.toString.call( obj ) === '[object Array]';
		},
	};

	_c.getScripts = function(scripts) {
		return new Promise(function(resolve, reject) {

			if( typeof scripts === 'string' ) {
				scripts = [ scripts ];
			}

			var count = scripts.length;
			var compare = 0;
			var output = [];

			function fetchScript(url) {
				if( ! url ) {
					return;
				}

				var method = 'getScript';
				if( url.match(/\.json$/) ) {
					method = 'getJSON';
				}
				_c.$[method](url)
					.done(function(data) {
						if( data ) {
							output.push(data);
						}
						compare++;
						if( compare === count ) {
							if( output.length === 1 ) {
								output = output[0];
							}
							resolve();
						}
					})
					.fail(function(jqxhr, settings, exception) {
						reject(exception);
					});
			}

			scripts.forEach(fetchScript);
		});
	};

	window.Clique = _c;
})(window.jQuery);
