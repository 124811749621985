
(function(factory) {
	'use strict';
	if(document.getElementById('map-distances')) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// globals
	var google = window.google;
	var controller = _c.MapController;
	var ScrollMagic = window.ScrollMagic;

	// elements
	var container = document.getElementById('map-distances');
	var $walkingList = _c.$('.walking-list');
	var $drivingList = _c.$('.driving-list');

	// vars
	var map;

	var locations = [];
	var locationsObject = {};
	var paths = [];
	var riverlineCoords = {
		lat : 41.8701807,
		lng : -87.6335375
	};

	// set marker icon and shape
	var iconRadius = 13;
	var symbol = {
		anchor        : new google.maps.Point(iconRadius, iconRadius),
		fillColor     : '#fff',
		fillOpacity   : 1,
		path          : 'M5,13a8,8 0 1,0 16,0a8,8 0 1,0 -16,0',
		strokeColor   : '#fff',
		strokeOpacity : 0.5,
		strokeWeight  : 10
	};
	var shape = {
		coords : [iconRadius, iconRadius, iconRadius],
		type   : 'circle'
	};

	function updateContent(_location) {
		_c.$('#map-distances + p').html('<strong>' + _location.title + '</strong><time>' + _location.duration + ' min</time>');
	}

	function updateMap(_location) {

		// vars
		var bounds = new google.maps.LatLngBounds();
		var coords = {
			lat : _location.lat,
			lng : _location.lng
		};

		// create bounds
		bounds.extend(riverlineCoords);
		bounds.extend(coords);

		// get center and zoom
		var center = bounds.getCenter();
		var zoom = controller.getZoom(bounds, {
			width  : container.clientWidth,
			height : container.clientHeight,
		});

		// remove current lines
		paths.forEach(function(_path) {
			_path.setMap(null);
		});

		// draw the line
		var path = new google.maps.Polyline({
			path : [
				riverlineCoords,
				coords
			],
			geodesic      : true,
			strokeColor   : '#fff',
			strokeOpacity : 1,
			strokeWeight  : 2
		});
		path.setMap(map);
		paths.push(path);

		// update map
		map.setCenter(center);
		map.setZoom(zoom);
		google.maps.event.trigger(map, 'resize');

		// set content
		setTimeout(function() {
			updateContent(_location);
		}, 0);
	}

	function onClick(e) {
		e.preventDefault();

		// vars
		var $target = _c.$(e.currentTarget);
		var $parent = $target.parent('li');

		// guard
		if( $parent.hasClass('active') ) {
			return;
		}

		// update ui
		_c.$('.map-lists .active').removeClass('active');
		$parent.addClass('active');

		// get location object
		var uid = $target.data('location-id') || $target.attr('data-location-id');
		var object = locationsObject[uid];

		// update the map bounds
		updateMap( object );
	}

	function addMainMarker() {

		// set marker icon and shape
		new google.maps.Marker({
			position : new google.maps.LatLng(riverlineCoords.lat, riverlineCoords.lng),
			map      : map,
			// clickable: true,
			icon     : {
				anchor        : new google.maps.Point(18.5, 18.5),
				fillColor     : '#495f75',
				fillOpacity   : 1,
				path          : 'M2.5,18.5a16,16 0 1,0 32,0a16,16 0 1,0 -32,0',
				strokeColor   : '#fff',
				strokeOpacity : 1,
				strokeWeight  : 5
			},
			shape : {
				coords : [18.5, 18.5, 16],
				type   : 'circle',
			},
		});
	}

	function addMarker(_location) {

		// create the marker
		var marker = new google.maps.Marker({
			position : new google.maps.LatLng(_location.lat, _location.lng),
			map      : map,
			icon     : symbol,
			shape    : shape,
		});

		// add to location
		_location.marker = marker;
	}

	function addLocations() {
		return new Promise(function(resolve) {
			locations.forEach(addMarker);
			resolve();
		});
	}

	function getTemplate(title, duration, id) {
		return [
			'<li>',
			'<a href="#" data-location-id="' + id + '">',
			'<p>' + title + '</p>',
			'<time>' + duration + ' min</time>',
			'</a>',
			'</li>'].join('');
	}

	function addToList(_location) {
		var template;

		// add to walking list
		if( _location.walking ) {
			template = getTemplate(_location.title, _location.walking, _location.uid);
			$walkingList.append( template );
		}

		// add to driving list
		if( _location.driving ) {
			template = getTemplate(_location.title, _location.driving, _location.uid);
			$drivingList.append( template );
		}
	}

	function addItems(_locations) {
		// console.log(_locations);
		locations = [];
		var didSet = false;
		return new Promise(function(resolve) {
			var tmp = _locations.filter(function(_location) {

				// guard
				if( _location.walking|| _location.driving ) {

					// set duration
					_location.duration = !! _location.duration ? _location.duration : _location.walking ? _location.walking : _location.driving;

					// get uid
					var uid = _c.utils.uid();
					_location.uid = uid;
					locations.push(_location);
					locationsObject[uid] = _location;

					return true;
				}
			});

			// sort array
			tmp.sort(function(a, b) {
				if( a.duration < b.duration ) {
					return -1;
				}
				if( a.duration > b.duration ) {
					return 1;
				}
				return 0;
			});

			// save to global object
			locations = tmp;

			// add items to list
			locations.forEach(function(_location) {

				if( ! didSet ) {
					didSet = true;
					updateContent(_location);
				}

				addToList(_location);
			});

			// bind click handler
			_c.$('.map-lists').on('click', 'a', onClick);

			resolve();
		});
	}

	function initDistanceMap() {
		return new Promise(function(resolve) {
			map = controller.createMap(container);
			map.setCenter(riverlineCoords);
			map.setZoom(16);
			var listener = map.addListener('tilesloaded', function() {
				google.maps.event.removeListener(listener);
				addMainMarker();

				resolve();
			});
		});
	}

	function onReady() {
		if( _c.support.hasTouch ) {
			return;
		}

		var $wrapper = _c.$('.map-wrapper');
		var $target = $wrapper.parent();
		var height = $target[0].clientHeight - window.innerHeight;
		var canContrinue = false;
		var progress;

		if( height < 1 ) {
			return;
		}

		// wrap container
		$wrapper.wrap('<div class="sticky-wrapper" />');

		function cb(_progress) {
			_progress = typeof _progress === 'undefined' ? progress : _progress;
			if( ! canContrinue ) {
				return;
			}
			$wrapper.css({
				transform : 'translate(0, ' + _progress * height + 'px)'
			});
		}

		var scene = new ScrollMagic.Scene({
			triggerElement : $target[0],
			duration       : height,
			triggerHook    : 'onLeave',
		})
		.on('start', function() {
			cb(0);
			_c.$win.on('scrollend.mapdistances', function() {
				cb();
			});
		})
		.on('progress', function(e) {
			progress = e.progress;
		})
		.on('end', function() {
			cb(1);
		})
		.on('enter', function() {
			canContrinue = true;
			_c.$win.on('scrollend.mapdistances', function() {
				cb();
			});
		})
		.on('leave', function() {
			canContrinue = false;
		})
		.addTo(_c.controller);

		_c.$win.one('resizeend.mapdistances', function() {
			height = $target[0].clientHeight - window.innerHeight;
			scene.duration(height);
			scene.triggerElement($target[0]);
			scene.update();
		});
	}

	function initMaps() {
		controller.getLocations()
			.then(addItems)
			.then(initDistanceMap)
			.then(addLocations)
			.then(onReady);
	}

	// init
	controller.ready(initMaps);
});
