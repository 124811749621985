
(function(factory) {
	'use strict';

	if( document.querySelector('[data-toggle-overlay]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	_c.$html.on('click', '[data-toggle-overlay]', function(e) {
		e.preventDefault();
		_c.$(this).closest('.section-overlay, .map-overlay').toggleClass('closed');
	});
});
